import React, { useEffect, useContext, useState } from 'react';
import {Row, Col } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { ChartsGoogle } from './ChartsGoogle';
import { BarChartsGoogle } from './BarChartsGoogle';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');


export default function IssueReport(props) {
    const {setSearchTopic, setSearchValue, searchTopic, searchValue } = props;
    const { generateDate, generateReturnDate } = useContext(ProductContext);
    const [issueDetails, setIssueDetails] =useState({dueBooks : [], nextDueBooks:[]})
    const [regYearCount, setRegYearCount] = useState();
    const [googleIssueData, setGoogleIssueData]= useState();
    const [isIssueDayCount, setIsIssueDayCount]=useState(false);
    const [googleReturnData, setGoogleReturnData]= useState();
    const [isReturnDayCount, setIsReturnDayCount]=useState(false);
    const [googleUserData, setGoogleUserData]= useState();
    const [isUserDayCount, setIsUserDayCount]=useState(false);
    const [searchResult, setSearchResult]=useState([]);
    const [loaded, setLoaded] = useState(false);
    const { enqueueSnackbar } = useSnackbar();


    useEffect(()=>{
      handleIssueSearch();
      },[])

      const handleIssueSearch=async()=>{
        //event.preventDefault();
        //console.log("issue book : ");
        try{
            setLoaded(false);
            //setDispMsg('');
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            //const searchValue = searchValue;
            //const searchTopic = searchTopic;
            await axios.get(`${config.api.invokeURL}/vjlibrary/reports/issue?clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
             'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
           //setDispMsg(response.data.length+' - Books Found');
            if(response.data.length>0)
            {
                setSearchResult(response.data);
                  processIssueReports(response.data);
                  initializeValues(response.data);
            }
            enqueueSnackbar('Issue Report loaded successfully',{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
            //console.log("error:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    

    }

      const processIssueReports=async(tsearchResult)=>{
        const todayDate=await generateDate();
        const nextDueDate=await generateReturnDate(7);
        const dueBooks=tsearchResult.filter((item)=>item.bookedTo<todayDate);
        //console.log("Due books :", dueBooks);
        const nextSevenDays=tsearchResult.filter((item)=>item.bookedTo<nextDueDate & item.bookedTo>todayDate);
        //console.log("Next Due books :", nextSevenDays);
        setIssueDetails({...issueDetails,
            dueBooks:dueBooks, nextDueBooks:nextSevenDays
        });
        processIssueTrendData(tsearchResult);
        processReturnTrendData(tsearchResult);
        //processData(searchResult);
      }

      const processIssueTrendData=async(tsearchResult)=>{
        const dayIssueCount={};
        for(var i =-30; i<=0; i++ ){
            //console.log("i : ", i);
            const nextDueDate=await generateReturnDate(i);
            const dueBooks=tsearchResult.filter((item)=>item.bookedOn===nextDueDate); 
            const ttemp=nextDueDate.toString();
            dayIssueCount[ttemp]=dueBooks.length;   
        }
        //console.log("day issue count ", dayIssueCount);
        processData(dayIssueCount,"Issue");
      }

      const processData=(rawData, type)=>{
        var regChartHeader=[];
        var regChartValues=[];
        var chartHearder="";
        if(type==="Issue"){
            chartHearder=["Date","Book's Issued"];
        }else if(type==="User"){
            chartHearder=["User","Book's Count"];
        }else{
            chartHearder=["Date","Book's Return"];
        }
        regChartHeader.push(chartHearder);    
        for(const data in rawData){
            const ttt=[data, rawData[data]];
            regChartHeader.push(ttt);
           // regChartHeader.push(rawData[data]);

            regChartValues.push(rawData[data]);
        //    console.log(data," - ", rawData[data]);

        }
        const regChartData={};
        regChartData.labels=regChartHeader;
        regChartData.series=regChartValues;
        setRegYearCount(regChartData);
        //console.log("Data : ", regChartData);
        var tdata=[]
        tdata.push(chartHearder);
        tdata.push(regChartHeader);
        tdata.push(regChartValues);
        if(type==="Issue"){
            setGoogleIssueData(regChartHeader);
            setIsIssueDayCount(true);
        }else if(type==="User"){
            setGoogleUserData(regChartHeader);
            setIsUserDayCount(true);
        }else {
            setGoogleReturnData(regChartHeader);
            setIsReturnDayCount(true);
        }
    }

    const processReturnTrendData=async(tsearchResult)=>{
        const dayIssueCount={};
        for(var i =0; i<=30; i++ ){
            //console.log("i : ", i);
            const nextDueDate=await generateReturnDate(i);
            const dueBooks=tsearchResult.filter((item)=>item.bookedTo===nextDueDate); 
            const ttemp=nextDueDate.toString();
            dayIssueCount[ttemp]=dueBooks.length;   
        }
        //console.log("day issue count ", dayIssueCount);
        processData(dayIssueCount, "Return");
      }

      const initializeValues=(tsearchResult)=>{
        var uniqueArray = removeDuplicates("bookedfor", tsearchResult);
        var sortedArray = uniqueArray.sort((a, b) => {return a.bookedfor - b.bookedfor;});
        //setCoursesAvailable(sortedcourseArray);
        //console.log("Courses Name : ", sortedArray);
        processUserTrendData(sortedArray, tsearchResult);
      }
    
      const removeDuplicates=(tprop, tsearchResult)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in tsearchResult) {
           lookupObject[tsearchResult[i][tprop]] = tsearchResult[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }

    const processUserTrendData=async(sortedArray, tsearchResult)=>{
        const dayIssueCount={};
        for(const i in sortedArray ){
            //console.log("i : ", i);
            const nextDueDate=await generateReturnDate(i);
            const dueBooks=tsearchResult.filter((item)=>item.bookedfor===sortedArray[i].bookedfor); 
            const ttemp=sortedArray[i].bookedfor;
            dayIssueCount[ttemp]=dueBooks.length;   
        }
        //console.log("day issue count ", dayIssueCount);
        //var sortedArray = uniqueArray.sort((a, b) => {return a.bookedfor - b.bookedfor;});
        processData(dayIssueCount,"User");
      }



  return (
    <div>
    <section className="catagory_section">
    <h3 style={{textAlign:"center"}}>Issue Report</h3>
    <div className="catagory_container4">
      <div className="container ">
        <div className="row">
          <div className="col-sm-6 col-md-4 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>{searchResult.length}</h5>
                <p>Books Issued</p>
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-4 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>{issueDetails.dueBooks.length}</h5>
                <p>Books Over Due</p>
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-4 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>{issueDetails.nextDueBooks.length}</h5>
                <p>Due in next 7 days</p>
              </div>
            </div>
            </div>
            </div>
        </div>
    </div>
        {/*<div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                            <div className="features-small-item">
                                <div className="icon">
                                <i style={{fontSize:"30px", fontStyle:"normal"}}>{searchResult.length}</i>
                                </div>
                                <h5 className="features-title">Books Issued</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>
                                    {issueDetails.dueBooks.length}</i>
                                </div>
                                <h5 className="features-title">Books Over Due</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>
                                    {issueDetails.nextDueBooks.length}</i>
                                </div>
                                <h5 className="features-title">Due in next 7 days</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}
        <div className='p-3'>
        {isIssueDayCount?
        <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
        <ChartsGoogle regYearCount={googleIssueData} titleMsg="Last 30 day's - Book's Issued Count"/>
            </Col>
        </Row>:null}
        {isReturnDayCount?
        <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
        <ChartsGoogle regYearCount={googleReturnData} titleMsg="30 day's - Book's Return Count"/>
            </Col>
        </Row>:null}
        {isUserDayCount?
        <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
        <BarChartsGoogle regYearCount={googleUserData} titleMsg="User Pattern"/>
            </Col>
        </Row>:null}
        </div>
    </section>
    </div>
  )
}
