import React, {useState, useContext, useEffect} from 'react';
import { Table } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import Preloader from '../../components/Preloader/Preloader';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function MyBooks() {
    const { validateLogin, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [bookResult, setBookResult] = useState([]);
    //const [bookSelect, setBookSelect]= useState();
    const [loaded, setLoaded] = useState(true);
    const [issuedBooks, setIssuedBooks]=useState([]);
    //const [show, setShow] = useState(false);
    //const [modalTitle, setModalTitle] = useState('')
    //const ref = useRef([]);
    const [dispMsg, setDispMsg] = useState('');

    useEffect(()=>{
        if(!loggedUser.isAuthenticated){
          validateLogin();
        }else{
            handleUserSearch();
        }
      },[loggedUser])

      const handleUserSearch=async()=>{
        try{
              setLoaded(false);
              const { idToken } = (await fetchAuthSession()).tokens ?? {};
              const searchValue=loggedUser.nickname;
              //const searchTopic="UserID";
            /*await axios.get(`${config.userapi.invokeURL}/dashboard/student/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
              headers: {
                Authorization: idToken,
                'x-api-key':config.userapi.key
              }
            }).then(async(response) => {
             console.log("response : ", response.data);
              if(response.data.length>0)
              {
                  setUserResult(response.data);
              }
              enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
              setLoaded(true);
          }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
        })*/
        const clientID="VJCollege";
        //console.log("search value :", searchValue);
        const tempTopic="UserID";
        await axios.get(`${config.api.invokeURL}/vjlibrary/issue?fetchKey=${searchValue}&fetchOption=${tempTopic}&clientID=${clientID}`, {
          headers: {
            Authorization: idToken,
           'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("response : ", response);
         //setDispMsg(response.data.length+' - Books Found');
         const bookCount = response.data.length;

              setIssuedBooks(response.data);
          
          //console.log("response : ", bookCount);
          if(response.data.length<=0){
            setDispMsg("You have " + bookCount +" books assigned to your name. Start using our digital library and enrich your knowledge");
          }else if(response.data.length>=18){
            setDispMsg("You have " + bookCount +" books assigned to your name. You are close to the target of 20 books. Return unused books immediately.");
          }else {
            setDispMsg("You have " + bookCount +" books assigned to your name. Enjoy our digital library");
          }
          enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
          setLoaded(true);
      }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
    })
          }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
          }
    }

    const handleSortKey =async(event, value)=>{
        event.preventDefault();
        console.log("clicked");
        if(value==="AccessionNo"){
          const tempOrginalValue=bookResult;
          setBookResult([]);
          //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
          var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.accessionNo - b.accessionNo;});
          console.log("Sorted AccessionNo Array ", sortedOptionArray);
          await setBookResult(sortedOptionArray);
        }else if(value==="BookTitle"){
          const tempOrginalValue=bookResult;
          //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
          var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.bookTitle - b.bookTitle;});
          console.log("Sorted Book Array ", sortedOptionArray);
          await setBookResult(sortedOptionArray);
        }else if(value==="BookingDate"){
          var tempOrginalValue=bookResult;
          //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
          var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.bookedOn - b.bookedOn;});
          tempOrginalValue.sort((a, b) => (a.name - b.name ? 1 : -1));
      
          console.log("Sorted Book Array ", tempOrginalValue);
          await setBookResult(sortedOptionArray);
        }
        
      }

  return (
    <div>
    <Helmet>
      <title>My Books - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="My Books - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="My Books - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    {!loaded && <Preloader show={loaded ? false : true} />}
    <h3 style={{textAlign:"center"}}>My Book's</h3>
      <div className='container'>
      <p> {dispMsg}</p>

        <Table striped bordered hover>
        <thead >
        <tr className='text-center'>
          <th>#</th>
          <th>Accession No  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Book Title <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Author  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Classification No <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Booking Date  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookingDate")}></i></th>
          <th>Return Date <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
        </tr>
        </thead>
        <tbody>
        {issuedBooks.map((item,index)=>{return(
          <tr key={index}>
            <td>{index+1}</td>
            <td>{item.accessionNo}</td>
            <td>{item.bookTitle}</td>
            <td>{item.bookAuthor}</td>
            <td>{item.bookClassificationNo}</td>
            <td>{item.bookedOn}</td>
            <td>{item.bookedTo}</td>
          </tr>
        )})}
        </tbody>
      </Table>
      </div>
    </div>
  )
}
