import React, {useState, useContext, useEffect} from 'react';
import IssueReport from './IssueReport';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import Preloader from '../../components/Preloader/Preloader';
import ReportHeader from './ReportHeader';
import BookReport from './BookReport';
//import CustomReport from './CustomReport';
import BookSearch from '../search/BooksSearch';
import {Helmet} from 'react-helmet-async';
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function HomeReports() {
    const { validateLogin, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(false);
    const [searchResult, setSearchResult]=useState([]);
    const [searchValue, setSearchValue]= useState('');
    const [searchTopic, setSearchTopic]=useState('AccessionNo');
    const [dispMsg, setDispMsg] = useState('');
    const [reportName, setReportName] = useState('Books');

    useEffect(()=>{
        if(!loggedUser.isAuthenticated){
          validateLogin();
        }else{
            //console.log("report name :", reportName);
          if(reportName==="Books"){
                handleBookSearch();
            }
            setLoaded(true);
        }
      },[loggedUser])

    const handleBookSearch=async()=>{
        //event.preventDefault();
        //console.log("book");
        try{
            setLoaded(false);
            setDispMsg('');
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            await axios.get(`${config.api.invokeURL}/vjlibrary/reports/books?clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
             'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response 1: ", response.data);
           //setDispMsg(response.data.length+' - Books Found');
            if(response.data.length>0)
            {
              setSearchResult(response.data);
            }
            //setSearchResult(response.data);
            enqueueSnackbar('Books Report loaded successfully',{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
            //console.log("error:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});

        }
    }

    {/*const handleIssueSearch=async()=>{
        //event.preventDefault();
        //console.log("issue book : ");
        try{
            setLoaded(false);
            //setDispMsg('');
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            //const searchValue = searchValue;
            //const searchTopic = searchTopic;
            await axios.get(`${config.api.invokeURL}/vjlibrary/reports/issue?clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
             'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
           //setDispMsg(response.data.length+' - Books Found');
            if(response.data.length>0)
            {
                setSearchResult(response.data);
            }
            enqueueSnackbar('Issue Report loaded successfully',{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
            //console.log("error:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    

    }*/}

    const handleToggleReport=(event, item)=>{
        event.preventDefault();
        if(item==="Issue"){
          //  handleIssueSearch();
        }else if(item==="Books"){
            handleBookSearch();
        }
        setReportName(item);
    }


  return (
    <div>
    <Helmet>
      <title>Reports - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Reports - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Reports - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    <div className='container' style={{paddingTop:"50px"}}>
      <header className="section">
      {!loaded && <Preloader show={loaded ? false : true} />}
        <h3 style={{textAlign:"center"}}>Library Reports</h3>
        <ReportHeader handleToggleReport={handleToggleReport} reportName={reportName}/>
       {reportName==="Issue" && loaded && <IssueReport setSearchTopic={setSearchTopic} setSearchValue={setSearchValue} searchValue={searchValue} searchTopic={searchTopic} />}
       {reportName==="Books" && loaded && <BookReport searchResult={searchResult} setSearchTopic={setSearchTopic} setSearchValue={setSearchValue} searchValue={searchValue} searchTopic={searchTopic} />}
       {/*reportName==="Custom" && <CustomReport searchResult={searchResult} setSearchTopic={setSearchTopic} setSearchValue={setSearchValue} searchValue={searchValue} searchTopic={searchTopic} />*/}
      {reportName==="Custom" && <BookSearch />}
      </header>
    </div>
    </div>
  )
}
